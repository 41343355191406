import axios from '../axios'

const  wordListService = (function(){
    
    
    let instance;
    function Service(){}

    Service.prototype.getWordList = function(){
       
        return axios.get(`wordlist`).then((res) => {
            return res.data;
        }).catch((err) => {
            return err;
        })

    }

/*    Service.prototype.findById = function(id) {
        return api
          .get(`wordlist/${id}`)
          .then(res => {
            return res.data;
          })
          .catch(err => {
            return err;
          });
      };

     Service.prototype.addWord = function(word) {

        return api.post('wordlist', word).then(res => {
            return res.data
        }).catch((err) => {
            return err
        })

    }

    Service.prototype.update = function(word) {

        return api.put('wordlist', word).then(res => {
            return res.data
        }).catch((err) => {
            return err
        })

    }

    Service.prototype.delete = function(_id) {

        return api.delete('wordlist', {params: _id}).then(res => {
            return res.data
        }).catch((err) => {
            return err
        })

    } */


    return {
        getInstance: () => {
            if(!instance)
            {
                instance = new Service();
            }

            return instance;
        }    
    }
})()

export default wordListService
