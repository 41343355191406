<template>
  <div
    class="bg global-blue"
    :style="{'min-height': 2200 + 'px'}"
  >
    <v-container>
      <!-- <eulogos></eulogos> -->
      <v-row>
        <v-col>
          <h1 class="my-8 global-title-sm hidden-md-and-up">{{ translations.title[locale] }}</h1>
          <h1 class="my-8 global-title hidden-sm-and-down">{{ translations.title[locale] }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card
            dark
            outlined
            class="bg "
            min-height="500"
          >
            <v-container v-if="!loading">
              <v-row>
                <v-col cols="12">
                  <wordcloud
                    :data="wordlist"
                    nameKey="name"
                    class="wc"
                    valueKey="value"
                    :color="colors"
                    :rotate="{from: 0, to: 0}"
                    :fontSize="$vuetify.breakpoint.smAndDown ? [13, 30] : [20, 40]"
                    :showTooltip="false"
                    :wordClick="wordClickHandler"
                    :wordPadding="$vuetify.breakpoint.smAndDown ? 12 : 6"
                    :fontScale="'sqrt'"
                  ></wordcloud>
                </v-col>
                <v-col id="below">
                  <v-card
                    v-if="selected.length > 0"
                    dark
                    outlined
                    class="dark-bg pa-2"
                  >
                    <template v-if="selected.length > 0">
                      <v-btn
                        small
                        text
                        dark
                        @click="selected = []"
                      >{{ translations.reset[locale] }}</v-btn>
                    </template>

                    <template v-for="(w,i) in wordlist">
                      <template v-if="selected.indexOf(w[locale]) > -1">
                        <v-chip
                          @click:close="selected = selected.filter(s => s !== w[locale])"
                          small
                          :color="$orange"
                          class="ma-1"
                          :close="true"
                          :key="i"
                        >{{ w[locale] }}</v-chip>
                      </template>
                    </template>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-if="selected.length > 0">
                <v-col>
                  <v-card
                    class="global-blue dark-bg"
                    flat
                    dark
                    min-height="500"
                  >
                    <v-card-title>
                      {{ locale == 'fi' ? 'Teemaa vastaavat kehittämisympäristöt' : 'Matching themes for development environment' }}
                      <v-spacer></v-spacer>
                    </v-card-title>
                    <v-data-table
                      class="dark-bg global-blue"
                      :headers="headers"
                      :items="dataTableItems"
                      style="{hover}"
                    >
                      <template v-slot:[`item.name`]="{ item }">
                        <router-link
                          class="link"
                          :to="{ name: 'environmentView', params: { id: item._id} }"
                        >{{ item.name }}
                        </router-link>
                      </template>
                      <template v-slot:[`item.link`]="{ item }">
                        <v-btn
                          class="ml-n3"
                          text
                          small
                          :to="{ name: 'environmentView', params: { id: item._id} }"
                        >{{ locale == 'fi' ? 'Lisätietoja' : 'More' }}</v-btn>
                      </template>
                      <template v-slot:[`item.wordlist`]="{ item }">
                        <template v-if="item.wordlist.length > 0">
                          <v-chip
                            @click="toggleChip(w)"
                            small
                            :color="selectedIds.includes(w) ? $orange : 'primary' "
                            class="ma-1 hidden-xs-only"
                            v-for="(w,wIndex) in item.wordlist"
                            :key="wIndex"
                          >#{{ tagName(w)[locale] }}</v-chip>

                        </template>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style scoped>
/* h1 {
  color: #f7941e;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
} */
.bg {
  height: 100%;
  padding-bottom: 150px;
  background: var(--v-primary-base) !important;
}
.dark-bg {
  background: var(--v-primary-base) !important;
  padding: 5px;
}
.wordCloud {
  height: 600px;
}
.link {
  cursor: pointer;
  color: white;
  text-decoration: none;
}
</style>

<script>
import environmentsService from "../../services/environments";
import wordListService from "../../services/wordlist";
// import eulogos from "../../components/EU_Logos_In_Pages";
const wordcloud = () => import("vue-wordcloud");
import { PORTAL_THEMES } from "../../constants/translations";

export default {
  name: "Themes",
  components: {
    wordcloud,
    // eulogos
  },
  data() {
    return {
      translations: PORTAL_THEMES,
      environments: {
        fi: [],
        en: [],
      },
      colors: ["#ff7843", "#ff712c", "#ff9029"],
      dataTableItems: [],
      selected: [],
      wordlist: [],
      loading: true,
      show: [],
      selectedIds: [],
      val: 0,
    };
  },
  methods: {
    toggleChip(id) {
      for (let key in this.wordlist) {
        if (
          this.selected.includes(this.wordlist[key][this.locale]) &&
          this.wordlist[key]._id == id
        ) {
          this.removeSelectedWithId(id);
          return;
        }
      }
      const newWord = this.wordlist.find((w) => w._id == id);
      if (newWord == null) return;
      this.selected = [...this.selected, ...[newWord[this.locale]]];
    },
    removeSelectedWithId(id) {
      this.wordlist.forEach(
        (w) =>
          (this.selected = this.selected.filter(
            (sel) => !(sel == w[this.locale] && w._id == id)
          ))
      );
    },
    wordClickHandler(name, value) {
      this.val = value;
      this.selected = [...this.selected, ...[name]];
      this.$vuetify.goTo(400, 0);
    },
    tagName(id) {
      const tag = this.wordlist.find((t) => id == t._id);
      return tag != null ? tag : {};
    },
    fetchwordlist() {
      wordListService
        .getInstance()
        .getWordList()
        .then((list) => {
          this.selected = [];
          this.wordlist = list.map((l) => {
            l.value = 10 + l.refs.length;
            l.name = l[this.locale];
            return l;
          });

          this.storage("wordlist_portal", JSON.stringify(this.wordlist));
        })
        .catch((err) => {
          // console.log("error ", err);
        });
    },
    fetchEnvironments() {
      environmentsService
        .getInstance()
        .findAll()
        .then((environments) => {
          this.environments[this.locale] = environments.map(
            (e) => e[this.locale]
          );

          this.storage("environments_portal", JSON.stringify(this.environments));
          
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    storage(key, data) {
      if (!data) {
        const cache = window.localStorage.getItem(key);
        if (cache) return cache;
        return;
      } else {
        window.localStorage.setItem(key, data);
      }
    },
    cache(){
      const wordlistCache = this.storage("wordlist_portal");
      const environmentsCache = this.storage("environments_portal");
      if (wordlistCache) this.contactItems = JSON.parse(wordlistCache);
      if (environmentsCache) this.environments = JSON.parse(environmentsCache);
      if (wordlistCache && environmentsCache){
        this.loading = false;
      } 
    }
  },
  watch: {
    selected(val) {
      this.show = this.wordlist.filter((s) => val.includes(s[this.locale]));
      this.selectedIds = this.show.map((s) => s._id);
      this.dataTableItems = this.environments[this.locale].filter((e) =>
        e.wordlist.find((w) => this.selectedIds.includes(w))
      );
    },
  },
  computed: {
    locale() {
      this.fetchwordlist();
      this.fetchEnvironments();
      return this.$store.state.locale;
    },
    headers() {
      return [
        {
          text: this.translations.titles[this.locale][0],
          align: "left",
          width: "15%",
          value: "name",
        },
        {
          text: this.translations.titles[this.locale][1],
          width: "45%",
          value: "wordlist",
        },
        {
          text: this.translations.titles[this.locale][2],
          width: "15%",
          value: "field",
        },
        {
          text: this.translations.titles[this.locale][3],
          width: "15%",
          value: "organization",
        },
        {
          text: this.translations.titles[this.locale][4],
          width: "25%",
          value: "link",
        },
      ];
    },
  },
  created() {
    this.cache()
    this.fetchwordlist();
    this.fetchEnvironments();
    this.loading = false;
  },
};
</script>